
export default {
  data() {
    return {
      menuVisible: false,
      openFonc: false,
    };
  },
  methods: {
    closeMenu() {
      this.$emit('closeMenu');
    }
  },
};
